import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'wealth-statergy',
    loadChildren: () =>
      import('./wealth-statergy/wealth-statergy.module').then((e) => e.WealthStatergyModule)
  },
  {
    path: 'wealth-statergy-options',
    loadChildren: () =>
      import('./wealth-strategy-options/wealth-strategy-options.module').then(
        (e) => e.WealthStrategyOptionsModule
      )
  },
  {
    path: 'wealth-statergy-soa',
    loadChildren: () =>
      import('./wealth-strategy-soa/wealth-strategy-soa.module').then(
        (e) => e.WealthStrategySOAModule
      )
  },
  {
    path: '401',
    loadChildren: () => import('./error/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: '404',
    loadChildren: () => import('./error/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: '',
    redirectTo: '/404',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled', // Enables scrolling to anchor fragments
  scrollPositionRestoration: 'enabled' // Restores scroll position on navigation
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
